var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.alwaysVisible || _vm.totalItems > _vm.itemsPerPage ? _c('div', {
    staticClass: "pagination"
  }, [_c('a', {
    staticClass: "btn prev",
    class: {
      disabled: _vm.isPrevDisabled
    },
    attrs: {
      "href": "javascript:void(0);"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.prevClickHandler.apply(null, arguments);
      }
    }
  }, [_c('svg', {
    attrs: {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M13.332 16.666 6.665 10l6.667-6.667",
      "stroke": "#4B5056",
      "stroke-width": "1.3",
      "stroke-miterlimit": "10"
    }
  })])]), _c('ul', _vm._l(_vm.pages, function (pageNo) {
    return _c('li', {
      key: 'pagination-' + pageNo,
      class: {
        current: _vm.currPageNo == pageNo
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.pageClickHandler(pageNo);
        }
      }
    }, [_c('a', {
      attrs: {
        "href": "javascript:void(0);"
      }
    }, [_vm._v(_vm._s(pageNo))])]);
  }), 0), _c('a', {
    staticClass: "btn next",
    class: {
      disabled: _vm.isNextDisabled
    },
    attrs: {
      "href": "javascript:void(0);"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.nextClickHandler.apply(null, arguments);
      }
    }
  }, [_c('svg', {
    attrs: {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M6.668 3.333 13.335 10l-6.667 6.667",
      "stroke": "#4B5056",
      "stroke-width": "1.3",
      "stroke-miterlimit": "10"
    }
  })])])]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }