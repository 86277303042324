var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.buttonType === 'button' ? _c('button', {
    class: ['btn', _vm.className, _vm.sizeClassName, _vm.appendClass],
    attrs: {
      "type": "button",
      "disabled": _vm.disabled
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.clickHandler.apply(null, arguments);
      }
    }
  }, [_vm._t("default")], 2) : _vm.buttonType === 'anchor' ? _c('a', {
    class: ['btn', _vm.className, _vm.sizeClassName, _vm.appendClass],
    attrs: {
      "href": "javascript:void(0)",
      "disabled": _vm.disabled
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.clickHandler.apply(null, arguments);
      }
    }
  }, [_vm._t("default")], 2) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }