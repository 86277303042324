var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-wrap"
  }, [_c('form', _vm._g({
    attrs: {
      "action": ""
    }
  }, _vm.submitEventHandlers), [_c('fieldset', [_vm.legend ? _c('legend', [_vm._v(_vm._s(_vm.legend))]) : _vm._e(), _vm.comment ? _c('h2', {
    domProps: {
      "innerHTML": _vm._s(_vm.comment)
    }
  }) : _vm._e(), _vm._t("default")], 2)]), _vm._t("append")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }