var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.itemType === 'input' && !_vm.isLastField ? _c('div', {
    staticClass: "field",
    class: {
      error: !_vm.isValid
    }
  }, [[_c('div', {
    staticClass: "label",
    class: {
      blind: _vm.labelBlind
    },
    domProps: {
      "innerHTML": _vm._s(_vm.label)
    }
  }), _vm._t("default"), !_vm.isValid ? _c('p', {
    staticClass: "text"
  }, [_vm._v(_vm._s(_vm.errorMessage))]) : _vm._e()]], 2) : _vm.itemType === 'input' && _vm.isLastField ? _c('div', {
    staticClass: "field last",
    class: {
      error: !_vm.isValid
    }
  }, [_vm._t("default"), !_vm.isValid ? _c('p', {
    staticClass: "text"
  }, [_vm._v(_vm._s(_vm.errorMessage))]) : _vm._e()], 2) : _vm.itemType === 'checkbox' ? _c('div', {
    class: {
      error: !_vm.isValid
    }
  }, [_vm._t("default"), !_vm.isValid ? _c('p', {
    staticClass: "text"
  }, [_vm._v(_vm._s(_vm.errorMessage))]) : _vm._e()], 2) : _vm.itemType === 'fragment' ? _c('fragment', [_vm._t("default"), !_vm.isValid ? _c('p', {
    staticClass: "p-error text"
  }, [_vm._v(_vm._s(_vm.errorMessage))]) : _vm._e()], 2) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }