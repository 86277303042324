var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "overlay",
    class: [_vm.popupInfo.overlayClass]
  }, [_c('div', {
    staticClass: "popup"
  }, [_c('div', {
    staticClass: "popup-header"
  }, [_c('i', {
    staticClass: "ico check"
  }), _c('h2', {
    domProps: {
      "innerHTML": _vm._s(_vm.popupInfo.title)
    }
  })]), _c('div', {
    staticClass: "popup-body",
    class: _vm.popupInfo.bodyClass
  }, [_c('p', {
    staticClass: "ta-c",
    domProps: {
      "innerHTML": _vm._s(_vm.popupInfo.message)
    }
  })]), _c('div', {
    staticClass: "btn-area"
  }, [_c('WButton', {
    attrs: {
      "size-class-name": "large",
      "class-name": "normal"
    },
    on: {
      "click": _vm.colseHandler
    }
  }, [_vm._v(" " + _vm._s(_vm.buttonText))])], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }