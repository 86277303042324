var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "input-wrap with-num"
  }, [_c('WInput', _vm._b({
    attrs: {
      "hide-error-text": "",
      "validate-when-blurred": false,
      "disabled": !_vm.isStartedTimer
    },
    on: {
      "input": _vm.inputHandler,
      "blur": _vm.blurHandler,
      "validate": _vm.validate
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_vm.isStartedTimer ? _c('span', {
          staticClass: "ticking"
        }, [_vm._v(_vm._s(_vm.remainTimeStr))]) : _vm._e()];
      },
      proxy: true
    }])
  }, 'WInput', _vm.wInputPops, false)), _vm._t("button")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }