import { render, staticRenderFns } from "./WIconRadio.vue?vue&type=template&id=541ffe56&scoped=true&"
import script from "./WIconRadio.vue?vue&type=script&lang=ts&"
export * from "./WIconRadio.vue?vue&type=script&lang=ts&"
import style0 from "./WIconRadio.vue?vue&type=style&index=0&id=541ffe56&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "541ffe56",
  null
  
)

export default component.exports