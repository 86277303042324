var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.model,
      expression: "model"
    }],
    class: _vm.selectClass,
    attrs: {
      "id": _vm.selectId,
      "disabled": _vm.disabled
    },
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.model = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }, function ($event) {
        return _vm.changeHandler($event.target.value);
      }]
    }
  }, [_vm.placeholder ? _c('option', {
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "value": "[select-placeholder]"
    }
  }, [_vm._v(_vm._s(_vm.placeholder))]) : _vm._e(), _vm._l(_vm.options, function (option, index) {
    return _c('option', {
      key: _vm.selectId + '-' + index,
      domProps: {
        "value": option.code,
        "selected": index === 0
      }
    }, [_vm._v(" " + _vm._s(option.text) + " ")]);
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }