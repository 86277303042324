var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "containerRef",
    staticClass: "swiper",
    class: _vm.containerClassName
  }, [_c(_vm.wrapperTagName, {
    tag: "component",
    staticClass: "swiper-wrapper",
    class: _vm.wrapperClassName
  }, [_vm._t("default")], 2), _vm.usePagination ? _c('div', {
    ref: "paginationRef",
    staticClass: "swiper-pagination"
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }