var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "switch"
  }, [_c('div', [_vm.title ? _c('h3', {
    domProps: {
      "innerHTML": _vm._s(_vm.title)
    }
  }) : _vm._e(), _vm.subTitle ? _c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.subTitle)
    }
  }) : _vm._e()]), _c('span', {
    staticClass: "onoff medium",
    class: {
      active: _vm.model
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.model,
      expression: "model"
    }],
    staticClass: "onoff-checkbox",
    attrs: {
      "id": _vm.switchId,
      "type": "checkbox",
      "name": _vm.switchId
    },
    domProps: {
      "checked": Array.isArray(_vm.model) ? _vm._i(_vm.model, null) > -1 : _vm.model
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.model,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.model = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.model = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.model = $$c;
        }
      }, _vm.changeHandler]
    }
  }), _c('label', {
    staticClass: "onoff-label",
    attrs: {
      "for": _vm.switchId
    }
  })])]);
}
var staticRenderFns = []

export { render, staticRenderFns }