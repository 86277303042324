var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "check-wrap"
  }, _vm._l(_vm.list, function (option, index) {
    return _c('div', {
      key: _vm.groupName + '-radio-' + index,
      staticClass: "check"
    }, [_c('label', {
      attrs: {
        "for": 'radio-' + option.code || option
      }
    }, [_c('input', {
      staticClass: "checkbox small",
      attrs: {
        "id": 'radio-' + option.code || option,
        "type": "radio",
        "name": _vm.groupName,
        "disabled": _vm.isDisabledOption(option)
      },
      domProps: {
        "checked": _vm.isCheckedOption(option)
      },
      on: {
        "change": function change($event) {
          return _vm.changeHandler(option);
        }
      }
    }), _vm._v(_vm._s(option.text || option) + " ")])]);
  }), 0);
}
var staticRenderFns = []

export { render, staticRenderFns }