var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "plan"
  }, [_vm._t("header-button"), _c('ul', {
    staticClass: "tabs small"
  }, _vm._l(_vm.list, function (tab, index) {
    return _c('li', {
      key: 'tab-plan-' + index,
      class: {
        active: _vm.activeIdx === index
      }
    }, [_c('a', {
      attrs: {
        "href": "javascript:void(0)"
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.clickHandler(tab, index);
        }
      }
    }, [_vm._v(_vm._s(tab.name))])]);
  }), 0), _vm._l(_vm.list, function (tab, index) {
    return _c('div', {
      key: 'tab-plan-content-' + index,
      staticClass: "tab-content",
      class: {
        active: _vm.activeIdx === index
      }
    }, [_vm._t(tab.id)], 2);
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }