var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "containerRef",
    staticClass: "swiper",
    class: _vm.containerClassName
  }, [_c('ul', {
    staticClass: "item-list story swiper-wrapper"
  }, [_vm._t("default")], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }