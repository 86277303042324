var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Fragment', [_c('input', {
    ref: "inputRef",
    staticClass: "input-text medium",
    class: {
      'none-err-icon': _vm.hideErrorIcon
    },
    attrs: {
      "disabled": _vm.disabled,
      "type": _vm.type,
      "placeholder": _vm.placeholder,
      "title": _vm.title,
      "maxlength": _vm.maxLength,
      "pattern": _vm.pattern,
      "readonly": _vm.readonly
    },
    domProps: {
      "value": _vm.value
    },
    on: {
      "input": _vm.inputHandler,
      "change": _vm.changeHandler,
      "blur": _vm.blurHandler,
      "touchstart": _vm.focusHandler,
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.enterHandler.apply(null, arguments);
      }
    }
  }), _vm._t("append")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }