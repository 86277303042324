var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "textarea"
  }, [_c('textarea', {
    ref: "textareaRef",
    attrs: {
      "placeholder": _vm.placeholder,
      "disabled": _vm.disabled,
      "readonly": _vm.readonly,
      "maxlength": _vm.maxLength
    },
    domProps: {
      "value": _vm.value
    },
    on: {
      "input": _vm.inputHandler,
      "change": _vm.changeHandler,
      "blur": _vm.blurHandler
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }