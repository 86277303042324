var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "overlay pop-notice",
    class: {
      active: _vm.isActive
    },
    attrs: {
      "id": "popNotice"
    }
  }, [_c('div', {
    staticClass: "popup"
  }, [_c('div', {
    staticClass: "popup-header"
  }, [_c('div', {
    staticClass: "tag notice",
    attrs: {
      "title": "공지사항"
    }
  }), _c('h2', {
    domProps: {
      "innerHTML": _vm._s(_vm.popupInfo.title)
    }
  })]), _c('div', {
    staticClass: "popup-body"
  }, [_c('div', {
    staticClass: "text-box ta-c",
    domProps: {
      "innerHTML": _vm._s(_vm.popupInfo.message)
    }
  })]), _c('div', {
    staticClass: "btn-area"
  }, [_c('WButton', {
    attrs: {
      "size-class-name": "large",
      "class-name": "normal"
    },
    on: {
      "click": _vm.colseHandler
    }
  }, [_vm._v(" " + _vm._s(_vm.buttonText) + " ")])], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }