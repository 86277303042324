var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "overlay pop-modify",
    class: {
      active: _vm.isActive
    }
  }, [_c('div', {
    staticClass: "popup"
  }, [_c('div', {
    staticClass: "btn-wrap"
  }, [_vm._l(_vm.buttons, function (button, index) {
    return _c('fragment', {
      key: 'fnc-btn-' + index
    }, [_c('WButton', {
      attrs: {
        "size-class-name": "medium",
        "class-name": button.class
      },
      on: {
        "click": function click($event) {
          return _vm.btnClickHandler(index);
        }
      }
    }, [_vm._v(" " + _vm._s(button.text) + " ")])], 1);
  }), _vm.useClose ? _c('WButton', {
    attrs: {
      "size-class-name": "medium",
      "class-name": "ok"
    },
    on: {
      "click": function click($event) {
        return _vm.closeBtnClickHandler(_vm.index);
      }
    }
  }, [_vm._v(" 닫기 ")]) : _vm._e()], 2)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }