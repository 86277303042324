var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "loading-wrap"
  }, [_c('span', {
    staticClass: "loading-img"
  }), _c('span', {
    staticClass: "text"
  }, [_vm._v("로딩중")])]);
}]

export { render, staticRenderFns }