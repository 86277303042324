var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "overlay",
    class: {
      'pop-report': _vm.type === 'REPORT',
      'ease pop-modify': _vm.type === 'REQUEST_EDIT',
      active: _vm.isActive
    }
  }, [_vm.type === 'REPORT' ? _c('div', {
    staticClass: "popup"
  }, [_c('div', {
    staticClass: "btn-wrap"
  }, [_c('button', {
    staticClass: "btn medium",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.reportClickHandler
    }
  }, [_vm._v("신고")]), _c('button', {
    staticClass: "btn medium ok",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.closeHandler
    }
  }, [_vm._v("취소")])])]) : _vm._e(), _vm.type === 'REQUEST_EDIT' ? _c('div', {
    staticClass: "popup"
  }, [_vm._m(0), _c('div', {
    staticClass: "popup-body"
  }, [_c('div', {
    staticClass: "check-wrap"
  }, [_c('div', {
    staticClass: "check"
  }, [_c('label', {
    attrs: {
      "for": "check1"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.createReportDto.content,
      expression: "createReportDto.content"
    }],
    staticClass: "checkbox small",
    attrs: {
      "id": "check1",
      "type": "radio",
      "name": "check",
      "value": "약국 상호가변경되었어요"
    },
    domProps: {
      "checked": _vm._q(_vm.createReportDto.content, "약국 상호가변경되었어요")
    },
    on: {
      "change": function change($event) {
        return _vm.$set(_vm.createReportDto, "content", "약국 상호가변경되었어요");
      }
    }
  }), _vm._v("약국 상호가변경되었어요 ")])]), _vm.createReportDto.content === '약국 상호가변경되었어요' ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.inputModel,
      expression: "inputModel"
    }],
    staticClass: "input-text medium",
    attrs: {
      "type": "text",
      "placeholder": "선택) 바뀐 상호명을 알려주세요."
    },
    domProps: {
      "value": _vm.inputModel
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.inputModel = $event.target.value;
      }
    }
  }) : _vm._e(), _c('div', {
    staticClass: "check"
  }, [_c('label', {
    attrs: {
      "for": "check2"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.createReportDto.content,
      expression: "createReportDto.content"
    }],
    staticClass: "checkbox small",
    attrs: {
      "id": "check2",
      "type": "radio",
      "name": "check",
      "value": "새로운 위치로 이전했어요"
    },
    domProps: {
      "checked": _vm._q(_vm.createReportDto.content, "새로운 위치로 이전했어요")
    },
    on: {
      "change": function change($event) {
        return _vm.$set(_vm.createReportDto, "content", "새로운 위치로 이전했어요");
      }
    }
  }), _vm._v("새로운 위치로 이전했어요 ")])]), _vm.createReportDto.content === '새로운 위치로 이전했어요' ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.inputModel,
      expression: "inputModel"
    }],
    staticClass: "input-text medium",
    attrs: {
      "type": "text",
      "placeholder": "선택) 새로운 주소를 입력해주세요."
    },
    domProps: {
      "value": _vm.inputModel
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.inputModel = $event.target.value;
      }
    }
  }) : _vm._e(), _c('div', {
    staticClass: "check"
  }, [_c('label', {
    attrs: {
      "for": "check3"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.createReportDto.content,
      expression: "createReportDto.content"
    }],
    staticClass: "checkbox small",
    attrs: {
      "id": "check3",
      "type": "radio",
      "name": "check",
      "value": "폐업되었거나 존재하지 않는 장소에요"
    },
    domProps: {
      "checked": _vm._q(_vm.createReportDto.content, "폐업되었거나 존재하지 않는 장소에요")
    },
    on: {
      "change": function change($event) {
        return _vm.$set(_vm.createReportDto, "content", "폐업되었거나 존재하지 않는 장소에요");
      }
    }
  }), _vm._v("폐업되었거나 존재하지 않는 장소에요 ")])]), _c('div', {
    staticClass: "check"
  }, [_c('label', {
    attrs: {
      "for": "check4"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.createReportDto.content,
      expression: "createReportDto.content"
    }],
    staticClass: "checkbox small",
    attrs: {
      "id": "check4",
      "type": "radio",
      "name": "check",
      "value": "영업시간이 변경되었어요"
    },
    domProps: {
      "checked": _vm._q(_vm.createReportDto.content, "영업시간이 변경되었어요")
    },
    on: {
      "change": function change($event) {
        return _vm.$set(_vm.createReportDto, "content", "영업시간이 변경되었어요");
      }
    }
  }), _vm._v("영업시간이 변경되었어요 ")])]), _vm.createReportDto.content === '영업시간이 변경되었어요' ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.inputModel,
      expression: "inputModel"
    }],
    staticClass: "input-text medium",
    attrs: {
      "type": "text",
      "placeholder": "선택) 변경된 영업시간을 입력해주세요."
    },
    domProps: {
      "value": _vm.inputModel
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.inputModel = $event.target.value;
      }
    }
  }) : _vm._e(), _c('div', {
    staticClass: "check"
  }, [_c('label', {
    attrs: {
      "for": "check5"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.createReportDto.content,
      expression: "createReportDto.content"
    }],
    staticClass: "checkbox small",
    attrs: {
      "id": "check5",
      "type": "radio",
      "name": "check",
      "value": "전화번호가 달라졌어요"
    },
    domProps: {
      "checked": _vm._q(_vm.createReportDto.content, "전화번호가 달라졌어요")
    },
    on: {
      "change": function change($event) {
        return _vm.$set(_vm.createReportDto, "content", "전화번호가 달라졌어요");
      }
    }
  }), _vm._v("전화번호가 달라졌어요 ")])]), _vm.createReportDto.content === '전화번호가 달라졌어요' ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.inputModel,
      expression: "inputModel"
    }],
    staticClass: "input-text medium",
    attrs: {
      "type": "text",
      "placeholder": "선택) 변경된 전화번호를 입력해주세요."
    },
    domProps: {
      "value": _vm.inputModel
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.inputModel = $event.target.value;
      }
    }
  }) : _vm._e(), _c('div', {
    staticClass: "check"
  }, [_c('label', {
    attrs: {
      "for": "check6"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.createReportDto.content,
      expression: "createReportDto.content"
    }],
    staticClass: "checkbox small",
    attrs: {
      "id": "check6",
      "type": "radio",
      "name": "check",
      "value": "판매약품이 변경되었어요"
    },
    domProps: {
      "checked": _vm._q(_vm.createReportDto.content, "판매약품이 변경되었어요")
    },
    on: {
      "change": function change($event) {
        return _vm.$set(_vm.createReportDto, "content", "판매약품이 변경되었어요");
      }
    }
  }), _vm._v("판매약품이 변경되었어요 ")])]), _vm.createReportDto.content === '판매약품이 변경되었어요' ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.inputModel,
      expression: "inputModel"
    }],
    staticClass: "input-text medium",
    attrs: {
      "type": "text",
      "placeholder": "선택) 변경된 판매약품을 입력해주세요."
    },
    domProps: {
      "value": _vm.inputModel
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.inputModel = $event.target.value;
      }
    }
  }) : _vm._e()])]), _c('div', {
    staticClass: "btn-area bottom fixed"
  }, [_c('WButton', {
    attrs: {
      "class-name": "normal",
      "size-class-name": "large",
      "append-class": "ok double"
    },
    on: {
      "click": _vm.closeHandler
    }
  }, [_vm._v(" 닫기 ")]), _c('WButton', {
    attrs: {
      "class-name": "bdr",
      "size-class-name": "large",
      "append-class": "request double"
    },
    on: {
      "click": _vm.reportClickHandler
    }
  }, [_vm._v(" 요청하기 ")])], 1)]) : _vm._e()]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "popup-header"
  }, [_c('h2', [_vm._v("수정을 제안할 항목을 선택해주세요")])]);
}]

export { render, staticRenderFns }