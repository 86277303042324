var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "overlay",
    class: {
      active: _vm.isActive
    }
  }, [_c('div', {
    staticClass: "popup"
  }, [_c('button', {
    staticClass: "btn close",
    attrs: {
      "type": "button",
      "title": "팝업 닫기"
    },
    on: {
      "click": _vm.closeHandler
    }
  }), _c('div', {
    staticClass: "img-wrap"
  }, [_c('img', {
    attrs: {
      "src": _vm.image,
      "alt": ""
    }
  })])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }