jQuery(function(){

	nav.init();

	$(window).on("resize", function () {
		nav.resize();
	});

	$(window).on("scroll", function () {
		nav.resize();
	});

	$('.overlay .btn.close, .overlay .ok').click(function (e) {
		e.preventDefault();
		var target = $(this).closest('.overlay');
		modalClose(target);
	});

	// 찜 버튼
	$('.btn.like-2, .btn.like-3').click(function(){
		$(this).toggleClass('active');
		return false;
	});

	tabs();
	onOff();
	btnCount();
	checkAll();
	setVh();
	inputSearch();
	fileAdd();
	fileRemove();
	selectSmall();
	imgCount();

});

//nav
var nav = {
	gnbScrollTop: 0,
	delta: 80,
	unMob: 960,

	//init
	init: function () {
		nav.checkViewport();
		nav.headerChange();

		$(document).on('click', '#hamburger', function () {
			if ($('body').hasClass('opened-nav')) {
				nav.closeNav();
			} else {
				nav.openNav();
			}
		});
	},

	//checkViewport
	checkViewport: function () {
		var wWidth = window.innerWidth;

		if(wWidth > nav.unMob) {
			$('body').removeClass('is-mobile');
		} else {
			$('body').addClass('is-mobile');
		}
	},

	//current
	current: function (dep1, dep2) {
		var gnb = $('#nav ul > li'),
			current1 = dep1 - 1,
			gnbDep = $(gnb).eq(current1).find('li'),
			current2 = dep2 - 1;

		//dep1
		if (!dep1 == "") {
			$(gnb).eq(current1).addClass('current');
			$(gnb).eq(current1).siblings().removeClass('current');
		}

		//dep2
		if (!dep2 == "") {
			$(gnbDep).eq(current2).addClass('current');
			$(gnbDep).eq(current2).siblings().removeClass('current');
		}
	},

	//openNav
	openNav: function () {
		$('body').addClass("opened-nav");
	},

	//closeNav
	closeNav: function () {
		$('body').removeClass("opened-nav");
	},

	//resize
	resize: function () {
		nav.checkViewport();
		nav.headerChange();

		$(window).on("scroll", function (e) {
			var st = $(this).scrollTop();

			//scroll Check
			if (st == 0) {
				$('body').removeClass('scroll-has');
			} else {
				$('body').addClass('scroll-has');

				if(st == $(document).height() - $(window).height()){
					$('body').addClass('scroll-end');
				} else {
					$('body').removeClass('scroll-end');
				}
			}

			if (Math.abs(nav.gnbScrollTop - st) <= nav.delta) return;

			//scroll up/down
			if ((st > nav.gnbScrollTop) && (nav.gnbScrollTop > 0)) {
				$('body').addClass('scroll-down').removeClass('scroll-up');
			} else {
				$('body').addClass('scroll-up').removeClass('scroll-down');
			}
			nav.gnbScrollTop = st;
		});
	},

	//headerChange
	headerChange: function () {
		var st = $(window).scrollTop(),
			$header = $("#header"),
			headerH = $header.outerHeight();

		//header Fix
		if (st > headerH) {
			$header.addClass("fixed");
		} else {
			$header.removeClass("fixed");
		}
	}
}

// modalOpen
function modalOpen(popId){
	$(popId).addClass("active");
	$('body').addClass("modal-opened");
}

// modalClose
function modalClose(popId){
	$(popId).removeClass("active");
	$('body').removeClass("modal-opened");
}

// tabs
function tabs(){
	$('.tabs.medium > li > a, .tabs.small > li > a, .tabs.calendar > li > a').each(function(){
		var tabTarget=$(this).attr('href');
		$(this).click(function(e){
			e.preventDefault();
			$(this).parent('li').addClass('active');
			$(this).parent('li').siblings('li').removeClass('active');
			$(tabTarget).addClass('active').siblings('.tab-content').removeClass('active');
			
			if($(this).parent().parent().hasClass('calendar')) {
				$(tabTarget).siblings('.tab-content').find('.calendar .days li').removeClass('from to between last');
			}
		});
	});

	$('.tabs.medium').each(function () {
		var divide = $(this).find('li').length;
		$(this).find('li').each(function () {
			$(this).css('width', parseFloat(100 / divide) + '%');
		});
	});
}

function onOff(){
	$('.onoff-checkbox').click(function() {
		if( $(this).is(':checked') ){
		    $(this).parent('.onoff').addClass('active');
		}
		else{
		    $(this).parent('.onoff').removeClass('active');
		}
	});
}

function btnCount(){
	$('.btn-area').each(function () {
		var divide = $(this).find('.btn').length;
		$(this).find('.btn').each(function () {
			if(divide == 2) {
				$(this).addClass('double');
			} else if(divide == 3) {
				$(this).addClass('triple');
			}
		});
	});

	$('.btn-radio').each(function () {
		var divide = $(this).find('label').length;
		$(this).find('label').each(function () {
			if(divide == 2) {
				$(this).parent().addClass('double');
			} else if(divide == 3) {
				$(this).parent().addClass('triple');
			}
		});
	});
}

function imgCount() {
	$('.img-all').each(function () {
		var countImg = $(this).find('.img-wrap').length;
		$(this).each(function() {
			if(countImg == 1) {
				$(this).addClass('count-1');
			} else if(countImg == 2) {
				$(this).addClass('count-2');
			} else if(countImg == 3) {
				$(this).addClass('count-3');
			}
		})
	});
}

// maxLengthCheck
function maxLengthCheck(obj){
	if(obj.value.length > obj.maxLength) {
		obj.value = obj.value.slice(0, obj.maxLength);
	}
}

function checkAll() {
	var $checkAll = $('.check-wrap .check-all input[type="checkbox"]');
	$checkAll.change(function () {
		var $this = $(this);
		var checked = $this.prop('checked');
		$checkAll.parent().parent().siblings().find('input[type="checkbox"]').prop('checked', checked);
	});

	var boxes = $('.check-wrap .check').find('input[type="checkbox"]');
	boxes.change(function () {
	  var boxLength = boxes.length;
	  var checkedLength = $('.check-wrap .check').find('input[type="checkbox"]:checked').length;
	  var selectAll = (boxLength == checkedLength);

	  $checkAll.prop('checked', selectAll);

	});
}

const setVh = () => {
	let vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty('--vh', vh + 'px');
	window.addEventListener('resize', function () {
	    let vh = window.innerHeight * 0.01;
	    document.documentElement.style.setProperty('--vh', vh + 'px');
	});
};


function inputSearch() {
	$('.search-wrap .input-text').on('keydown, keyup', function () {	
		var searchWord = $(this).val();
		var searchLength = searchWord.length;
		if (searchLength >= 1) {
			$(this).parent().parent().addClass('active');
			$(this).parent('.input-wrap').find('.btn.clear').show();
		} else {
			$(this).parent().parent().removeClass('active');
			$(this).parent('.input-wrap').find('.btn.clear').hide();
		}
	});

	$('.search-wrap .btn.clear').click(function() {
		$(this).siblings('.input-text').val('');
		$(this).hide();
	});
}

//fileAdd
function fileAdd() {
	//File upload
	var btnAtt = $('.add-file');
	var sel_files = [];
	var image_count = 0;
	var image_add_count = 0;
	$('.add-file').on('change',function(e) {

		var ext = $(this).val().split('.').pop().toLowerCase();
		if ($.inArray(ext, ['gif', 'jpg', 'jpeg', 'png']) == -1) {
			alert("사진 파일만 등록 가능합니다");
			return false;
		}

		var files = e.target.files;
		var fileArr = Array.prototype.slice.call(files)
		all_cnt = fileArr.length;
		be_cnt = $('.upload-file').length;
		total_cnt = all_cnt * 1 + be_cnt * 1;
		image_count = be_cnt;
		console.log(be_cnt);
		console.log(all_cnt);
		console.log(total_cnt);
		for (f of fileArr) {
			if (total_cnt > 3) {
				alert('이미지는 최대 3개 첨부가능합니다.');
				break;
			}
			imageLoader(f);
		}

		dt = new DataTransfer();
		for (f in sel_files) {

			var file = sel_files[f];
			dt.items.add(file);
		}
		btnAtt.files = dt.files;
		return false;
	});

	imageLoader = function (file) {
		sel_files.push(file);
		var reader = new FileReader();
		reader.onload = function (ee) {
			$(makeDiv(ee.target.result, file)).appendTo('.upload-wrap').last();
			$('.btn-upload .text-wrap').html('<div class="text-wrap">사진<span class="file-count">'+image_count+'</span>/3</div>')
		}
		reader.readAsDataURL(file);
	}

	makeDiv = function (img, file) {
		html = '<div class="upload-file"> ' +
			'<div class="img-wrap">' +
			'<img src ="'+img+'"  class="file-preview centered"></div>' +
			'<button type="button" class="btn delete btn_delFile" data-delfile="' + file.name + '" data-id="image.jpg"><i class="ico delete-black"><span>삭제</span></i></button>' +
			'</div>';
		image_count++;
		image_add_count++;
		return html
	}


	$(document).on('click', '.btn_delFile', function () {
		delFile = $(this).data('delfile');
		for (var i = 0; i < sel_files.length; i++) {
			if (delFile == sel_files[i].name) {
				sel_files.splice(i, 1);
			}
		}
		dt = new DataTransfer();
		for (f in sel_files) {
			var file = sel_files[f];
			dt.items.add(file);
		}
		btnAtt.files = dt.files;
		$('[data-delfile="' + delFile + '"]').parent().remove();
		$('.add-file').val('');
		image_count =  $('.upload-file').length;
		$('.btn-upload .text-wrap').html('<div class="text-wrap">사진<span class="file-count">'+image_count+'</span>/3</div>')
	});

}

//File remove
function fileRemove(t) {
	$([''])
	var me = $(t).parent('.upload-file'),
		myCon = $(me).closest('.upload-wrap');
	$(me).remove();

	var upCount = $(myCon).children('.upload-file').length,
		countIn = $(myCon).find('.file-count');
	$(countIn).text( upCount );

	var fileInput = $(myCon).find('input');
	var maxFileCnt = 3;		// 첨부파일 최대 개수
	if( upCount >= maxFileCnt){
		$(fileInput).attr('disabled', true);
	} else{
		$(fileInput).removeAttr('disabled');
	}
}

function profilePhoto() {
	var camera = document.getElementById('newProfilePhoto');
	var frame = document.getElementById('profilePhoto');

	camera.addEventListener('change', function(e) {
		var file = e.target.files[0];
		frame.src = URL.createObjectURL(file);
	});
}

// Select Small
function selectSmall(){
	var select_title = $('.select.small > a');

	select_title.click(function () {
		$(this).parent('.select.small').toggleClass('active');
	});

	$('.select.small .select-list > li').on('click', function () {
	  var li_value = $(this).text();

	  $(this).parent().siblings('a').text(li_value);
	  $(this).parent().parent().removeClass('active').addClass('selected');
	});
}

// HEADER SCROLL
function enterpriseHeader() {
	var scrollApply = false;
	var nav_init_pos = 0;

	function set_menu_pos() {
	   nav_init_pos = $('.swiper.shop').innerHeight() - $('#header').innerHeight();
	}

	set_menu_pos();
  $(window).on('resize', function() {
  	set_menu_pos();
  	$(window).trigger('scroll');
  });

  $(window).on('scroll', function() {
      if ($(window).scrollTop() > nav_init_pos) {
          if(!scrollApply) {
              $('#header').removeClass('clear');
              scrollApply = true;
          }
      }
      else {
          if(scrollApply) {
          	$('#header').addClass('clear');
              scrollApply = false;
          }
      }
  });
}

function locationList() {
	var move_position = '';
	var move_start_position = 0;
	var move_end_position = 0;
	var move_animation_check = false;

	var window_height = $(window).height();
	var tab_height = $('.container.location .tab-content.active').innerHeight();
	var pop_max_pop_position = window_height/9;
	var pop_min_pop_position = window_height - window_height/8;
	
	function touchStart(e) {
	    e.preventDefault();
	    move_start_position = e.changedTouches[0].clientY;
	    move_position = new Array();
	}

	function touchEnd(e) {
	    e.preventDefault();
	    position_check = move_position[position_length-1] - move_position[position_length-2];

	    if(move_animation_check == true){
	        return false;
	    }

	    if(position_check > 0){
	        move_animation_check = true;
	        $('#addressList').animate( { top : pop_min_pop_position} , 400 , null , function(){move_animation_check = false;} );
	        $('#addressList').addClass('close').removeClass('open');
	        return false;
	    }

	    if(position_check < 0){
	        move_animation_check = true;
	        $('#addressList').animate( { top : pop_max_pop_position} , 400 , null , function(){move_animation_check = false;} );
	        $('#addressList').addClass('open').removeClass('close');
	        return false;
	    }

	}


	function touchMove(e) {
	    e.preventDefault();
	    move_position.push(e.changedTouches[0].clientY);
	    position_length = move_position.length;

	    if(position_length==1){
	        return false;
	    }

	    if(move_animation_check == true){
	        return false;
	    }

	    cur_y = move_position[position_length-1] - move_position[position_length-2];
	    obj = $('#addressList').offset();
	    cur_position = obj.top + cur_y;

	    if(cur_position < pop_max_pop_position || cur_position > pop_min_pop_position){
	        return false;
	    }


	    $('#addressList').css('top',cur_position);
	}
	var addressList = document.getElementById("addressList");
	addressList.addEventListener("touchstart", touchStart, { passive: false, capture : true });
	addressList.addEventListener("touchmove", touchMove, { passive: false, capture : true });
	addressList.addEventListener("touchend", touchEnd, { passive: false, capture : true });
}

function addressLayer() {
	var move_position = '';
	var move_start_position = 0;
	var move_end_position = 0;
	var move_animation_check = false;

	var window_height = $(window).height();
	var pop_max_pop_position = window_height - 295;
	var pop_min_pop_position = window_height - 40;

	function touchStart(e) {
		e.preventDefault();
		move_start_position = e.changedTouches[0].clientY;
		move_position = new Array();
	}

	function touchEnd(e) {
		e.preventDefault();
		position_check = move_position[position_length-1] - move_position[position_length-2];

		if(move_animation_check == true){
		 return false;
		}

		if(position_check > 0){
		 move_animation_check = true;
		 $('#addressList2').animate( { top : pop_min_pop_position} , 400 , null , function(){move_animation_check = false;} );
		 $('#addressList2').addClass('close').removeClass('open');
		 return false;
		}

		if(position_check < 0){
		 move_animation_check = true;
		 $('#addressList2').animate( { top : pop_max_pop_position} , 400 , null , function(){move_animation_check = false;} );
		 $('#addressList2').addClass('open').removeClass('close');
		 return false;
		}

	}

	function touchMove(e) {
		e.preventDefault();
		move_position.push(e.changedTouches[0].clientY);
		position_length = move_position.length;

		if(position_length==1){
		 return false;
		}

		if(move_animation_check == true){
		 return false;
		}

		cur_y = move_position[position_length-1] - move_position[position_length-2];
		obj = $('#addressList2').offset();
		cur_position = obj.top + cur_y;

		if(cur_position < pop_max_pop_position || cur_position > pop_min_pop_position){
		 return false;
	}


	$('#addressList2').css('top',cur_position);
	}
	var addressList = document.getElementById("barArea2");
	addressList.addEventListener("touchstart", touchStart, { passive: false, capture : true });
	addressList.addEventListener("touchmove", touchMove, { passive: false, capture : true });
	addressList.addEventListener("touchend", touchEnd, { passive: false, capture : true });
}


function fullCalendar() {
	
}