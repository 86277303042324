var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('fragment', [_vm.initDataLoaded ? [_c('div', {
    staticClass: "skip-nav"
  }, [_c('a', {
    attrs: {
      "href": "#content"
    }
  }, [_vm._v("본문 바로가기")])]), _c('router-view')] : _vm._e(), _c('WAlertManager'), _vm.isLoading ? _c('WLoading') : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }