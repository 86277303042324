var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "btn-check"
  }, [_vm._l(_vm.list, function (option, index) {
    var _domProps;

    return [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.model,
        expression: "model"
      }],
      key: 'chk-grp-input-' + index,
      attrs: {
        "id": _vm.groupName + '-' + index,
        "type": "checkbox",
        "name": _vm.groupName,
        "disabled": _vm.isDisabledOption(option)
      },
      domProps: (_domProps = {
        "value": option.code,
        "checked": option.ckecked
      }, _domProps["checked"] = Array.isArray(_vm.model) ? _vm._i(_vm.model, option.code) > -1 : _vm.model, _domProps),
      on: {
        "change": function change($event) {
          var $$a = _vm.model,
              $$el = $event.target,
              $$c = $$el.checked ? true : false;

          if (Array.isArray($$a)) {
            var $$v = option.code,
                $$i = _vm._i($$a, $$v);

            if ($$el.checked) {
              $$i < 0 && (_vm.model = $$a.concat([$$v]));
            } else {
              $$i > -1 && (_vm.model = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.model = $$c;
          }
        }
      }
    }), _c('label', {
      key: 'chk-grp-label-' + index,
      attrs: {
        "for": _vm.groupName + '-' + index
      }
    }, [_vm._v(_vm._s(option.text))])];
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }