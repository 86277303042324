var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: _vm.warpClassName
  }, [_c('label', {
    attrs: {
      "for": _vm.chkId
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.model,
      expression: "model"
    }],
    ref: "inputRef",
    staticClass: "checkbox",
    class: _vm.sizeClassName,
    attrs: {
      "id": _vm.chkId,
      "type": "checkbox",
      "name": _vm.chkId
    },
    domProps: {
      "checked": Array.isArray(_vm.model) ? _vm._i(_vm.model, null) > -1 : _vm.model
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.model,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.model = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.model = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.model = $$c;
        }
      }, _vm.changeHandler]
    }
  }), _vm._t("default")], 2), _vm.showMore ? _c('a', {
    attrs: {
      "href": "javascript:void(0)"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.moreClickHandler.apply(null, arguments);
      }
    }
  }, [_c('i', {
    staticClass: "ico arrow-small"
  })]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }