var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tab-content calendar active"
  }, [_c('div', {
    ref: "calendarRef"
  }), _c('button', {
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.testHandler.apply(null, arguments);
      }
    }
  }, [_vm._v("테스트")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }