import { render, staticRenderFns } from "./WDialogImage.vue?vue&type=template&id=66200367&scoped=true&"
import script from "./WDialogImage.vue?vue&type=script&lang=ts&"
export * from "./WDialogImage.vue?vue&type=script&lang=ts&"
import style0 from "./WDialogImage.vue?vue&type=style&index=0&id=66200367&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66200367",
  null
  
)

export default component.exports