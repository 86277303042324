var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "overlay pop-modify",
    class: {
      active: _vm.isActive
    }
  }, [_c('div', {
    staticClass: "popup"
  }, [_c('div', {
    staticClass: "btn-wrap"
  }, [_c('WButton', {
    attrs: {
      "size-class-name": "medium",
      "class-name": "normal"
    },
    on: {
      "click": function click($event) {
        return _vm.btnClickHandler('modify');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.modifyText))]), _c('WButton', {
    attrs: {
      "size-class-name": "medium",
      "class-name": "normal"
    },
    on: {
      "click": function click($event) {
        return _vm.btnClickHandler('delete');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.deleteText))]), _c('WButton', {
    attrs: {
      "size-class-name": "medium",
      "class-name": ""
    },
    on: {
      "click": function click($event) {
        return _vm.btnClickHandler('close');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.closeText))])], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }