var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "overlay pop-cease",
    class: {
      active: _vm.isActive
    },
    attrs: {
      "id": "popCease"
    }
  }, [_c('div', {
    staticClass: "popup"
  }, [_c('div', {
    staticClass: "popup-header"
  }, [_c('i', {
    staticClass: "ico check"
  }), _c('h2', {
    domProps: {
      "innerHTML": _vm._s(_vm.message)
    }
  })]), _c('div', {
    staticClass: "btn-area"
  }, [_c('WButton', {
    attrs: {
      "size-class-name": "large",
      "class-name": _vm.leftBtnClass
    },
    on: {
      "click": function click($event) {
        return _vm.btnClickHandler('left');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.leftBtnText) + " ")]), _c('WButton', {
    attrs: {
      "size-class-name": "large",
      "class-name": _vm.rightBtnClass
    },
    on: {
      "click": function click($event) {
        return _vm.btnClickHandler('right');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.rightBtnText) + " ")])], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }